// ----------------------------------------
// Calculates the required size
// with custom settings
// and passes these to bg
// ----------------------------------------
import React from 'react';
//
import Image from './image';

const withDimensions = props => {
  // Bounds or dimensions are null, return original image
  if (!props.bounds) {
    return props;
  }

  let dpi = 1;
  if (
    typeof document !== 'undefined' &&
    window.devicePixelRatio !== 'undefined'
  ) {
    dpi = window.devicePixelRatio;
  }

  return Object.assign({}, { ...props }, { dpi });
};

export default props => {
  const smartImage = withDimensions(props);
  return <Image {...smartImage} />;
};
